/* Colores Base */
/*
  Amarillo: #F9A900;
  Negro: #0E0E10;
  Rojo: #BB1F11; 
*/

@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Cabin", sans-serif !important;
  overflow: auto !important;  
}

#root {
  background-color: #EFF2F4;
}

/* Resources */
.py-310 {
  padding-top: .310rem !important;
  padding-bottom: .310rem !important;
}

.py-375 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.c-pointer {
  cursor: pointer;
}

.text-red {
  color: #BB1F11;
}

.dot-success {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #198754;
}

.modal-xxl {
  --bs-modal-width: 1272px !important;
}

.border-bottom-radius {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

/* Scroll Bars */
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width:10px;
}

::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #0E0E10;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
  border-radius: 10px;  
}

/* Selección */
::selection {
  color: #0E0E10;
  background: #F9A900;
}

/* Firefox */
::-moz-selection {
  color: #0E0E10;
  background: #F9A900;
}

/* Títulos */
h1, h2, h3, h4, h5, h6 {
  color: #444444 !important;
}

/* Text */
.text-orange {
  color: #e84424 !important;
}

.text-green {
  color: #198754 !important;
}

/* Backgrounds */
.bg-light {
  background-color: #f7f9fc !important;
}

/* Tables */
table {
  border-color: #EBEDEF !important;
}

table thead th {
  font-size: .905rem;
  font-weight: 400;
  color: #5a5f6f !important;
}

table thead th:not(:last-child) {
  border-right: .25px solid #FFF;
}

table tbody tr td {
  font-weight: 500;
  vertical-align: middle;
}

.table tbody tr:last-child td {
  border-bottom-width: 0 !important;
}

/* table thead tr th:first-child,
table tbody tr td:first-child {
  padding-left: 1rem;
} */
/*
.table-hover>tbody>tr:hover>* {
  background-color: #e84424 !important;
}
*/

.table-active {
  --bs-table-bg-state: #e9ecef !important;
}

.table-scrollable {
  max-height: 300px;
}

/* Navs */
.nav-fill .nav-item .nav-link, 
.nav-justified .nav-item .nav-link {
  cursor: pointer;
}

.nav-tabs .nav-link {
  cursor: pointer;
  font-weight: bold;
  color: #444444;
}

/* .card-header-tabs {
  border-bottom: 1px solid #EBEDEF !important;
} */

.card-header-tabs .nav-link:focus:not(.active), 
.card-header-tabs .nav-link:hover:not(.active) {
  border-color: transparent !important;
  color: #F9A900 !important;
}

.card-header-tabs .nav-link.active {
  color: #F9A900 !important;
}

/* Nav Underline */
.nav-underline .nav-link {
  color: #444444 !important;
}

.nav-underline .nav-link.active, 
.nav-underline .show>.nav-link {
  color: #F9A900 !important;
}

/* Side Menú*/
.sideMenu {
  height: 100%;
  padding: .75rem;
  background-color: #0E0E10;
}

.sideMenu .nav-pills li:not(:last-child) {
  margin-bottom: .75rem;
}

.sideMenu ul.nav-pills .nav-link {
  padding: 12px 17px !important;
  border-radius: 14px;
  cursor: pointer;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, .5);
  transition: .2s ease-in-out;
}

.sideMenu ul.nav-pills .nav-link:hover:not(.nav-link.active) {
  color: #FFF;
  background-color: rgba(255, 255, 255, .1);
}

.sideMenu ul.nav-pills .nav-link.active {
  color: #0E0E10;
  background-color: #F9A900;
}

#menuMobile .offcanvas-body {
  background-color: #0E0E10;
}

#menuMobile .offcanvas-body button {
  border: 0;
  border-radius: 6px;
  color: rgba(255, 255, 255, .5);
  background-color: #0E0E10;
}

#menuMobile .offcanvas-body button i {
  margin-right: .65rem;
}

#menuMobile .offcanvas-body button:not(:last-child) {
  margin-bottom: .35rem;
}

#menuMobile .offcanvas-body button.active {
  color: #0E0E10;
  background-color: #F9A900;
}

/* Header */
header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: #ffffff;*/
}

header img {
  max-width: 140px;
}

/* Buttons */
.btn {
  font-weight: 600 !important;
}

.btn-close {
  transition: .2s ease-in-out;
}

.btn-close:focus {
  box-shadow: none !important;
}

.btn-yellow {
  color: #0E0E10 !important;
  background-color: #F9A900 !important;
}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active {
  color: #FFF !important;
  background-color: #0E0E10 !important;
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  border-color: #F9A900 !important;
}

.btn-dark {
  color: #FFF !important;
  background-color: #0E0E10 !important;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  color: #0E0E10 !important;
  border-color: #F9A900 !important;
  background-color: #F9A900 !important;
}

.btn-danger {
  color: #FFF !important;
  border-color: #BB1F11 !important;
  background-color: #BB1F11 !important;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  color: #BB1F11 !important;
  border-color: #BB1F11 !important;
  background-color: transparent !important;
}

.btn-outline-danger {
  border-color: #BB1F11 !important;
  color: #BB1F11 !important;
  background-color: transparent !important;
  transition: .2s ease-in-out;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active {
  color: #FFF !important;
  border-color: #BB1F11 !important;
  background-color: #BB1F11 !important;
}

.btn-outline-orange {
  border-color: #e84424 !important;
  color: #e84424 !important;
  background-color: transparent !important;
  transition: .2s ease-in-out;
}

.btn-outline-orange:hover {
  color: #fff !important;
  background-color: #e84424 !important;
}

.btn-check:checked+.btn, 
.btn.active, 
.btn.show, 
.btn:not(.btn-link):first-child:active, 
:not(.btn-check)+.btn:active {
  color: #FFF !important;
  border-color: #0E0E10 !important;
  background-color: #0E0E10 !important;
}

.btn-link:focus,
.btn-link:active {
  background-color: transparent !important;
}

.btn-group-sm>.btn,
.btn-sm {
  border-radius: .35rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0E0E10 !important;
}

/* Custom Bootstrap */
/* Cards */
.card {
  border-radius: 12px !important;
  border-color: #EBEDEF !important;
}

/* .card-header {
  padding: 1rem !important;
  border-bottom: 1px solid #EBEDEF !important;
} */

.card-header:first-child {
  border-radius: 12px 12px 0 0 !important;
}

.card-header h3,
.card-header h4 {
  margin-bottom: 0;
}

.card-body {
  background-color: #fff !important;
}

.card-title {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  transition: .2s ease-in-out;
}

.card-body-fh {
  height: auto;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
}

/* Custom Card Inside */
.card-inside {
  width: 100%;
  height: auto;
  border: 1px solid #dee2e6;
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: .2s ease-in-out;
}

.card-inside:not(:last-child) {
  margin-bottom: 1rem;
}

.card-inside.c-pointer:hover {
  border-color: #F9A900 !important;
  background-color: rgba(249, 169, 0, .05);
  /* box-shadow: 0 0 0 1px #F9A900,
              0 0 0 1px #F9A900,
              0 0 0 1px #F9A900,
              0 0 0 1px #F9A900; */
}

.card-inside.c-pointer:hover .card-title {
  background-color: rgba(232, 68, 36, .05) !important;
}

.card-inside.active-card {
  border-color: #F9A900 !important;
  background-color: rgba(249, 169, 0, .05);
}

.card-inside.active-card .card-title {
  background-color: rgba(232, 68, 36, .05) !important;
}

/* Forms */
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assets/background-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.item-flex img {
  object-fit: cover;
  object-position: 25% 50%;
}

.form-signin {
  max-width: 330px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-signin img {
  max-width: 200px;
  margin-bottom: 2rem;
}

.item-flex {
  flex: 0 1 50%;
}

.form-control:focus,
.form-select:focus {
  border-color: #F9A900 !important;
  box-shadow: none !important;
}

.label {
  font-weight: bold;
  color: #444444;
}

.form-label {
  font-size: .905rem;
  color: #5a5f6f;
}

.form-check-input:checked {
  border-color: #F9A900 !important;
  background-color: #F9A900 !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

/* React Select */
/* .css-13cymwt-control {
  border-radius: .375rem !important;
  border-color: #dee2e6 !important;
}

.css-1jqq78o-placeholder {
  color: #212529 !important;
}

.css-t3ipsp-control {
  border-color: #e84424 !important;
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
} */

/* React Modals */
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.modal {
  display: block !important;
}

.modal-content {
  background-color: #f7f9fc !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

/* Responsive */
@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  header img {
    max-width: 150px;
  }
  .login-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-image: none;
  }
  .card-body-fh {
    max-height: calc(100vh - 218px) !important;
  }
  .card-body-filter {
    max-height: calc(100vh - 250.72px) !important;
    overflow-y: auto;
  }
  .react-modal {
    width: 35%;
  }
  .form-control-width {
    width: 130px !important;
  }
  .form-select-width {
    width: 192px !important;
  }
  .form-date-width {
    width: 150px !important;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}